/**
 * Created by preference on 2020/09/11
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 5.1.实时库存列表  */
  getStockList: params => {
    return API.POST('api/stock/list', params)
  },

  /** 5.7.产品库存分布  */
  getStock_productEntity: params => {
    return API.POST('api/stock/productEntity', params)
  },
  // 导出库存
  stockStockExcel:params=>{
    return API.exportExcel('api/stock/stockExcel', params)
  }
}